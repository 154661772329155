.help-menu {
    padding: 0;
    margin: 0;
    @include breakpoint(small down) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    li {
        list-style: none;
        margin: 30px 0;
        @include breakpoint(small down) {
            margin: 0 0 16px;
        }
    }

    a {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: relative;
        transition: 0.5s color ease;
        &::after {
            content: "";
            transition: 0.5s all ease;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: absolute;
            height: 2px;
            width: 0;
            background: $darkblue;
            left: 0;
            bottom: -3px;
        }
        &:hover::after {
            width: 100%;
        }
        &.help-menu-active {
            pointer-events: none;
            &::after {
                width: 100%;
            }
        }
    }
}

.help-contact-img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

// faq page
.faq-item {
    position: relative;
    border-bottom: 1px solid;
    padding: 18px 0;
    cursor: pointer;
    transition: all 300ms ease;

    h3 {
        @include breakpoint(small down) {
            padding-right: 30px;
        }
    }

    &:hover {
        background-color: #f0f0f5;
    }

    > div {
        display: none;
        animation-name: faq-answer;
        animation-duration: 1.5s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &.item-open {
        > div {
            display: block;
        }
        .faq-icon {
            transform: rotateZ(180deg);
            transform-origin: center;
        }
        &:hover {
            background: none;
        }
    }
}

.faq-icon {
    width: 20px;
    position: absolute;
    right: 24px;
    top: 26px;
    transform: rotateZ(0deg);
    transition: all 700ms ease;
    @include breakpoint(small down) {
        right: 0;
    }
}

@keyframes faq-answer {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.help-buttons {
    @include breakpoint(medium down) {
        margin-bottom: 60px;
    }
    @include breakpoint(small down) {
        flex-direction: column;
        margin-bottom: 35px;
    }

    .button.empty-brown {
        @include breakpoint(small down) {
            margin-bottom: 15px;
            text-align: center;
        }
    }
}

// iubenda
.iubenda_legal_document {
    h1 {
        line-height: 29px;
        font-size: 27px;
    }
}
