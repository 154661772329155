@import url('https://fonts.googleapis.com/css?family=Roboto:300,700,900');

// Bebas font face
@font-face {
  font-family: Bebas Neue;
  src: url(/skins/telablu/fonts/bebas-neue/bebasneue_book-webfont.woff2) format('woff2'),
       url(/skins/telablu/fonts/bebas-neue/bebasneue_book-webfont.woff) format('woff');
  font-weight: 300;
}

@font-face {
  font-family: Bebas Neue;
  src: url(/skins/telablu/fonts/bebas-neue/bebasneue_regular-webfont.woff2) format('woff2'),
       url(/skins/telablu/fonts/bebas-neue/bebasneue_regular-webfont.woff) format('woff');
  font-weight: 400;
}