// Colors
$darkblue: #000D1D;
$lightblue: rgb(19, 30, 41);
$brown: rgb(156, 95, 21);
$white: white;
$light-gray: rgb(247, 247, 251);
$red: #cc4b37;
$light-red: #f9ecea;
$light-brown: #ECEBE7;
$sold-out: #500505;
$grey: #989898;

// Fonts
$bebas: "Bebas Neue", Helvetica, sans-serif;
$roboto: "Roboto", Helvetica, sans-serif;
$minion-pro: "Minion Pro", Helvetica, sans-serif;
$body-font-family: $roboto;
$body-font-color: $darkblue;

// Slick slider
$slick-loader-path: "../plugin/slick/";
$slick-font-path: "../plugin/slick/fonts/";
$slick-loader-path: "../plugin/slick/";

// Font awesome
$fa-font-path: "../fonts/font-awesome/";

// Off canvas
$offcanvas-size: 350px;
$offcanvas-exit-background: rgba($darkblue, 0.4);
$offcanvas-background: $light-brown;
$offcanvas-shadow: 0 0 10px hsla(213, 100%, 6%, 0.3);
$offcanvas-overlap-zindex: 10;