.shop-product {
    .slider-home-arrow {
        margin-top: 51vh;
        position: fixed;
        @include breakpoint(medium down) {
            position: absolute;
            margin-top: 39.5vw;
        }
        @include breakpoint(small down) {
            margin-top: calc(49vw + 53px);
        }
    }

    #arrow-right {
        right: 37vw;
        @include breakpoint(small down) {
            right: 15px;
        }
    }

    #arrow-left {
        @include breakpoint(small down) {
            left: 15px;
        }
    }
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.product-details {
    justify-content: center;
}

.product-3-boxes {
    position: relative;
    padding: 30px 0;

    @include breakpoint(medium down) {
        padding: 0px 25px;
    }

    @include breakpoint(small down) {
        margin-bottom: 20px;
        text-align: center;
    }

    &[data-open] {
        cursor: pointer;
    }

    &.coming-soon {
        &::before {
            content: "";
            position: absolute;
            background-color: #828282;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0.6;
        }
        &::after {
            content: "Coming soon";
            position: absolute;
            top: 30%;
            left: 0;
            right: 0;
            font-family: Bebas Neue, Helvetica, sans-serif;
            font-size: 35px;
            color: #000d1d;
        }
    }

    a {
        display: block;
    }

    img {
        width: 80px;
        height: 80px;
    }
}

.product-options {
    h3 {
        margin: 0 0 8px;
    }
}

.product-sidebar {
    width: 22vw;
    z-index: 1;
    padding: 70px 0 0;
    margin: 0 4vw 0 auto;
    min-height: 100vh;
    @include breakpoint(medium down) {
        width: auto;
        padding: 30px 0 60px;
        margin: 0 auto;
        min-height: 0;
        max-width: 400px;
    }
    @include breakpoint(small down) {
        position: static;
        width: 100%;
        padding: 30px 15px 0;
        transform: translate3d(0, 0, 0) !important;
    }

    &.sidebar-giftcard {
        @include breakpoint(small down) {
            margin-top: -60px;
        }
    }

    h1 {
        letter-spacing: 2px;
    }
}

.prices {
    margin-top: 10px;
    line-height: normal;

    p {
        display: inline-block;
        &:nth-child(2) {
            padding-left: 6px;
        }
    }
}

.retail-price {
    display: block;
    text-align: center;
    color: #464b4e;
}

.short-description {
    border-top: 1px solid;
    border-bottom: 1px solid;
    margin-bottom: 14px;
    margin-top: 10px;

    p,
    ul {
        margin: 30px 40px;
    }

    ul {
        padding: 0;
        li::marker {
            color: #989898;
        }
    }
}

.addtocart-form {
    input {
        border: none;
    }
}

.product-colors {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 12px;
    padding: 0 0 14px;
    list-style: none;
    border-bottom: 1px solid $darkblue;

    li {
        display: inline-block;
        padding: 2px;
        width: 25%;

        a {
            display: block;
            height: 39px;
            background-position: center center;
        }

        &.active a {
            box-shadow: inset 0 0 0 3px $darkblue;
        }
    }
}

.product-sizes {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        padding: 2px;
        width: 25%;

        input[type="radio"] + label {
            background-color: $light-gray;
            padding: 8px 0 6px;
            font-size: 14px;
            margin: 0;
            font-weight: 700;
            transition: 150ms;
            width: 100%;
            text-align: center;
            line-height: 1.8;
        }

        input {
            display: none;
        }

        input[type="radio"]:checked + label,
        input[type="radio"] + label:hover {
            background-color: $darkblue;
            color: white;
        }

        span {
            background-color: #d6d4d4;
            color: #989898;
            padding: 8px 0 6px;
            font-size: 14px;
            font-weight: 700;
            width: 100%;
            text-align: center;
            display: inline-block;
            cursor: default;
            line-height: 1.8;
        }
    }
}

.sizes-table {
    color: $brown;
    margin: 10px 0 0;
    display: block;
}

#product-slider {
    @include breakpoint(small down) {
        height: calc(105vw + 53px);
    }

    + .wrapper-no-img {
        @include breakpoint(medium down) {
            padding-top: 15px;
        }
    }

    &.slick-initialized .slick-slide {
        display: block;
    }

    .product-slide {
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include breakpoint(medium down) {
            height: 70vw;
            background-position: 0;
        }
        @include breakpoint(small down) {
            height: 105vw;
        }
    }
}

#product-slider-mobile {
    @include breakpoint(small down) {
        //height: calc(105vw + 53px);
    }

    &.giftcard-slider {
        position: absolute;
    }

    + .wrapper-no-img {
        @include breakpoint(medium down) {
            padding-top: 15px;
        }
    }

    &.slick-initialized .slick-slide {
        display: block;
    }

    .product-slide {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @include breakpoint(medium down) {
            height: 70vw;
        }
        @include breakpoint(small down) {
            height: 126vw;
            background-position-y: 30%;
        }
    }
}

.product-slide, .two-image-slide {
    position: relative;

    .close-button {
        position: absolute;
        top: 24px;
        right: 24px;
        transform: rotate(45deg);
    }
}

.slick-lightbox {
    .slick-prev,
    .slick-next {
        display: none !important;
    }

    .slick-lightbox-close {
        position: absolute;
        line-height: normal;
        right: 20px;
        border-radius: 50px;
        top: 20px;
        cursor: pointer;
        transition: opacity 0.15s;
        background-color: $white;
        color: $black;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;

        &::before {
            content: "";
            background-image: url("/skins/telablu/images/close-popup.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 16px;
            height: 16px;
        }

        @include breakpoint(medium down) {
            overflow-y: auto;
            max-height: 100%;
        }
    }
}

.product-description-2 {
    background-color: #ecebe7;
    height: 100%;
    padding: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint(medium down) {
        padding: 60px 70px;
    }
    @include breakpoint(small down) {
        padding: 25px 25px;
    }
    &.popup.size {
        background-color: none;
    }

    h3 {
        letter-spacing: 1px;
        @include breakpoint(small down) {
            font-size: 55px;
            line-height: 55px;
            margin-bottom: 25px;
        }
    }

    div:not(:last-child) {
        border-bottom: 1px solid;
        padding-bottom: 9px;
        margin-bottom: 9px;
    }
}

svg path,
svg rect {
    fill: white;
}

.loader-container {
    position: relative;

    .loader {
        position: absolute;
        right: 44px;
        top: 18px;
        display: none;
    }
}

#video-popup {
    display: flex;
    padding-top: 50px;
}

.detail-image {
    @include background-center;
    @include breakpoint(medium down) {
        min-height: 550px;
    }
    @include breakpoint(small down) {
        min-height: 350px;
    }
}

#size-popup {
    border: 1px dashed;

    h3 {
        letter-spacing: 1px;
        padding: 0 25px;
        line-height: normal;
    }

    @include breakpoint(medium down) {
        height: min(90vh, 560px);

        .table-size-row,
        #table-size-close {
            max-height: 100%;
            overflow-y: auto;
        }
    }
}

.size-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    th {
        padding: 14px 0;
        @include breakpoint(small down) {
            font-size: 14px;
        }
    }

    td {
        border: 1px solid $darkblue;
        padding: 8px 0;
        width: 25%;
    }
    &.popup-size {
        td {
            border: 1px solid #999999;
            font-size: 14px;
        }
        &.chino {
            td {
                width: 18%;
            }
        }
        th {
            padding: 4px 0px 14px 0;
        }
    }

    tbody {
        tr:nth-child(even) {
            background-color: $light-gray;

            &.popup-size {
                background-color: $light-brown;
            }
        }

        tr:first-child td {
            border-top: none;
        }

        tr td:first-child {
            border-left: none;
        }

        tr td:last-child {
            border-right: none;
        }

        tr:last-child td {
            border-bottom: none;
        }
    }
}

div#lhc_status_container {
    z-index: 3;
    @include breakpoint(small down) {
        display: none !important;
    }
}

#not-available-email {
    @include breakpoint(medium down) {
        height: min(90vh, 640px);
    }

    .not-available-email-content {
        max-width: 350px;
        margin: 25px auto 0;
        padding: 45px;

        @include breakpoint(medium down) {
            max-height: 100%;
            overflow-y: auto;
            padding: 50px 15px 30px;
        }
    }

    .product-options {
        margin-bottom: 15px;
        h3 {
            display: none;
        }
    }
}

// Giftcard
.giftcard-options {
    text-align: center;
    border: 2px solid $darkblue;
    margin: 22px 0 20px;

    .giftcard-curr-price {
        position: relative;
    }

    .giftcard-other-prices {
        display: none;
    }

    a {
        display: block;
        padding: 14px 0 12px;
        &:hover {
            background-color: #f0f0f5;
        }
    }

    .faq-icon {
        width: 18px;
        right: 20px;
        top: 20px;
    }

    &.item-open {
        .faq-icon {
            transform-origin: center;
            transform: rotate(180deg);
        }

        .giftcard-other-prices {
            display: block;
        }
    }
}

// popup shipping / returns
.popup-table-row {
    display: flex;
    margin-top: -1px;
    @include breakpoint(small down) {
        flex-direction: column;
        margin-bottom: 25px;
    }
}

.popup-table-col {
    border: 1px solid #b9b6b0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(small down) {
        width: 100%;
        margin-top: -1px;
    }

    &.col-small {
        padding: 11px 14px;
        width: 15%;
        @include breakpoint(medium down) {
            width: 20%;
            padding: 11px;
        }
        @include breakpoint(small down) {
            width: 100%;
        }

        span {
            white-space: nowrap;
        }
    }

    &.col-big {
        margin-left: -1px;
        margin-right: -1px;
        padding: 11px 18px;
        width: 70%;
        @include breakpoint(medium down) {
            width: 60%;
        }
        @include breakpoint(small down) {
            width: 100%;
            margin: -1px 0 0;
        }
    }
}

.popup-table-days {
    display: block;
    font-size: 11px;
    letter-spacing: -0.02em;
    margin-top: -4px;
}

.pop-table-header {
    margin: 0 0 25px;

    h3 {
        margin: 12px 0 0;
    }

    img {
        width: 80px;
        @include breakpoint(small down) {
            width: 65px;
        }
    }
}

.popup-shipping-returns {
    .popup-shipping-returns-content {
        padding: 30px 40px 37px !important;

        @include breakpoint(medium down) {
            padding: 30px 15px 37px !important;
            max-height: 100%;
            overflow-y: auto;
        }
    }
}

//popup taglie

.img-popup-size {
    /* margin-top: 11px; */
    /* height: 100%; */

    &.chino {
        width: 100%;
        box-sizing: border-box;
        margin: auto;
        display: flex;
        @include breakpoint(small down) {
            width: 50%;
        }
    }
    &.knitwear {
        width: 100%;
        box-sizing: border-box;
        margin: auto;
        display: flex;
        @include breakpoint(small down) {
            width: 70%;
        }
    }
}
.icon-popup-size {
    width: 75px;
}
.title-category-popup {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 0px;
    &.knitwear {
        @include breakpoint(small down) {
            margin-top: 20px;
        }
    }
}
#table-size-measure-popup {
    background-color: $light-brown;
}

#table-size {
    background-color: $light-brown;
}

.reveal {
    &.table-size {
        padding: 3rem;
        @include breakpoint(small down) {
            padding: 1rem;
        }
        @media screen and (min-width: 40em) {
            width: 700px;
        }

        .table-size-row {
            @include breakpoint(small down) {
                overflow-y: auto;
                max-height: 100%;
            }
        }
    }
}
.paragraph-banner {
    margin-top: 30px;
    margin-bottom: 30px;
}

.img-popup-container {
    margin: auto 0;
    padding-right: 30px;
    @include breakpoint(small down) {
        padding-right: 0px;
    }
}
.table-popup-container {
    margin: auto 0;
}

.table-popup-knitwear {
    margin-top: 0px;
    @include breakpoint(small down) {
        margin-top: 20px;
    }
}

.close-popup {
    position: absolute;
    color: #8a8a8a;
    cursor: pointer;
    right: 2.2rem;
    top: 1.5rem;
    font-size: 2.4em;
}

.title-popup {
    margin-top: 15px;
}

.left-image {
    margin-right: 3px;
    background-position: center;
}

.right-image {
    margin-left: 3px;
    background-position: center;
}

.two-image-slider {
    display: flex;
    height: 100vh;
    @include breakpoint(small down) {
        height: calc(105vw + 53px);
    }
}

.two-image-slide {
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
}

.fixed {
    position: fixed;
    top: 56px;
    left: 0px;
    z-index: -1;
}

#mobile-image-viewer {
    position: relative;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: $white;
    visibility: visible;
    transform: scale(var(--scale-factor, 1));

    &:not(.open) {
        display: none;
    }

    > img {
        width: 100%;
    }

    .ios-image-cover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &:not(.ios) .ios-image-cover {
        display: none;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        line-height: normal;
        right: 20px;
        border-radius: 50px;
        top: 20px;
        cursor: pointer;
        transition: opacity 0.15s;
        background-color: white;
        color: #000d1d;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
    }
}

.relative {
    position: relative;
}

.block {
    display: block;
}

#arrow-left-related,
#arrow-right-related {
    margin-top: 0 !important;
    top: calc(50% - 16px);
}

#arrow-left-related {
    left: 32px;
}

#arrow-right-related {
    right: 32px;
}
