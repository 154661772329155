@import "../settings";

.shopping-cart {
    padding-top: 21px;
    padding-bottom: 40px;
    @include breakpoint(small down) {
        padding-bottom: 20px;
    }

    .cart-update {
        @include breakpoint(small down) {
            padding-bottom: 0;
        }
    }
}

.cart-product {
    margin-bottom: 40px;
}

.cart-product-name {
    text-transform: uppercase;
    margin-bottom: 4px;
    font-weight: 200;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @include breakpoint(small down) {
        margin-top: 0;
        font-size: 22px;
    }
}

.cart-product-price {
    text-align: right;
    font-weight: bold;
    font-size: 22px;
}

.total-result {
    list-style: none;
    padding: 0;

    li {
        display: flex;
        margin: 10px;
        .divider {
            height: 1px;
            background-color: $darkblue;
        }

        h4 {
            margin: 0;
            flex: 2;
        }

        .total-result-in {
            text-align: right;
            flex: 1;
        }
    }
}

.off-cart-product {
    display: flex;
    margin-top: 20px;

    img {
        max-width: 120px;
    }
}

.off-cart-descr {
    width: 100%;
    padding-left: 15px;

    > div:not(:first-child) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.off-cart-title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    span {
        font-size: 28px;
    }
}

.off-cart-total {
    margin-top: 7px;
}

#offCanvasCartContainer {
    height: 100%;
}

.cart-numbers {
    display: flex;
    margin-bottom: 45px;
    padding-top: 45px;
    @include breakpoint(small down) {
        margin-bottom: 10px;
        padding-top: 30px;
        justify-content: space-around;
    }
}

.cart-numbers-single {
    width: 85px;
    text-align: center;
    margin-right: 50px;
    @include breakpoint(small down) {
        width: auto;
        margin-right: 0;
    }

    span {
        border: 1px solid $brown;
        padding: 10px;
        display: block;
        width: 50px;
        height: 50px;
        line-height: 28px;
        border-radius: 50px;
        margin: 0 auto 4px;
        font-weight: 700;
        font-size: 20px;
        @include breakpoint(small down) {
            width: 40px;
            height: 40px;
            padding: 6px;
        }
    }

    p {
        @include breakpoint(small down) {
            display: none;
        }
    }

    &.number-active span {
        border: 1px solid $brown;
        color: white;
        background-color: $brown;
    }
}

.cart-summary {
    .total-result {
        border-top: 1px solid;
        padding-top: 12px;
        @include breakpoint(small down) {
            padding-bottom: 20px;
        }

        &.loading {
            opacity: 0.2;
        }

        li {
            margin: 0;
            align-items: center;
        }

        .total-price {
            border-top: 1px solid;
            padding-top: 10px;
            margin-top: 10px;
        }
    }

    .cart-product-price {
        font-size: 16px;
        font-weight: 300;
    }

    .cart-product {
        @include breakpoint(medium down) {
            display: none;
        }
    }
}

.border-right {
    border-right: 1px solid;
    padding-right: 30px;
    @include breakpoint(medium down) {
        border-right: none;
        padding-right: 0;
    }
}

.not-found-message {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $light-brown;

    h1 {
        margin-top: 0;
        margin-bottom: 10px;
    }

    span {
        padding-right: 10px;
    }
}

.payment-button {
    width: 100%;
    text-align: left;
    border: 2px solid;
    padding: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    transition: all 0.15s;

    &:hover {
        color: white;
        border-color: $darkblue;
    }

    &:last-child {
        margin-bottom: 45px;
    }

    .fa {
        font-size: 30px;
    }

    span:last-child {
        font-size: 20px;
        padding-left: 20px;
        @include breakpoint(small down) {
            font-size: 16px;
        }
    }
}

.custom-button {
    width: 100%;
    text-align: left;
    border: 2px solid;
    cursor: pointer;
    margin-bottom: 20px;
    transition: all 0.15s;

    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;

    @media screen and (max-width: 39.9375em) {
        height: 45px;
    }

    img {
        width: 35px;
    }

    &:hover {
        color: white;
        background-color: $darkblue !important;
        border-color: $darkblue;
    }

    &:last-child {
        margin-bottom: 45px;
    }

    .fa {
        font-size: 30px;
    }

    span:last-child {
        font-size: 20px;
        padding-left: 20px;
        @include breakpoint(small down) {
            font-size: 16px;
        }
    }
}

.coupon-code {
    .input-blue {
        height: 50px;
        margin-bottom: 5px;
    }

    .button.empty-brown {
        width: 100%;
    }
}

.choose-shipping {
    border: 1px solid;
    margin: 0;
    padding: 18px 34px;
    margin-bottom: 15px;

    .radio {
        margin: 0;
    }

    .radio-label {
        margin: 0;
        width: 100%;
    }

    .choose-shipping-price {
        float: right;
        font-weight: 700;
    }
}

#giftcard_date {
    cursor: pointer;
}

.plus,
.minus,
.plus-cart,
.minus-cart,
.button-cart-remove {
    cursor: pointer;
}
