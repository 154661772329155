@font-face {
  font-family: 'Minion Pro';
  src: url('../fonts/MinionPro/MinionPro-Semibold.eot');
  src: url('../fonts/MinionPro/MinionPro-Semibold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MinionPro/MinionPro-Semibold.woff') format('woff'),
      url('../fonts/MinionPro/MinionPro-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro';
  src: url('../fonts/MinionPro/MinionPro-Regular.eot');
  src: url('../fonts/MinionPro/MinionPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MinionPro/MinionPro-Regular.woff') format('woff'),
      url('../fonts/MinionPro/MinionPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro';
  src: url('../fonts/MinionPro/MinionPro-Bold.eot');
  src: url('../fonts/MinionPro/MinionPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MinionPro/MinionPro-Bold.woff') format('woff'),
      url('../fonts/MinionPro/MinionPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// font align
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }

// font colors
.white { color: $white; }
.brown { color: $brown; }
.red { color: $red; }
.light-grey{ color: $grey; }

// font family
.bebasneue { font-family: $bebas };
.roboto { font-family: $roboto };
.minion-pro { font-family: $minion-pro; }

// font size and weight
.regular-18 {
  font-size: 18px;
  font-weight: 400;
}

.regular-14{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.bold {
  font-weight: 600;
}

.book-30 {
  font-size:30px;
  font-weight: 300;
  letter-spacing: 12px;
  line-height: 34px;
}

.book-50 {
  font-size: 50px;
  font-weight: 300;
  letter-spacing: 12px;
  line-height: 54px;
  @include breakpoint(small down){
    font-size: 40px;
    line-height: 44px;
  }
}

.book-60 {
  font-size: 60px;
  font-weight: 300;
  letter-spacing: 12px;
  line-height: 64px;
  @include breakpoint(small down){
    font-size: 50px;
    line-height: 54px;
  }
}

.book-70 {
  font-size: 70px;
  font-weight: 300;
  letter-spacing: 12px;
  line-height: 74px;
  @include breakpoint(small down) {
    font-size: 40px;
    line-height: 44px;
  }
}

.book-125 {
  font-size: 125px;
  font-weight: 300;
  letter-spacing: 12px;
  line-height: normal;
  @include breakpoint(small down) {
    font-size: 40px;
  }
}

.bold-50 {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
  line-height: 60px;
  @include breakpoint(medium down) {
    font-size: 30px;
    line-height: normal;
  }
}

.bold-40 {
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  line-height: 50px;
  @include breakpoint(small down) {
    font-size: 30px;
    line-height: normal;
  }
}

.bold-32 {
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  line-height: 35px;
}

.bold-70 {
  font-size: 70px;
  font-weight: 700;
  margin: 0;
  @include breakpoint(small down) {
    font-size: 40px;
    line-height: normal;
  }
}

.bold-18 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  line-height: 26px;
}

.bold-16 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.light-40 {
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
}

.light-32 {
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  @include breakpoint(medium down){
    font-size:28px;
    font-weight: 300;
    line-height: 36px;
  }
}

.light-22 {
  font-size: 22px;
  font-weight: 300;
  line-height: 36px;
  @include breakpoint(small down) {
    font-size: 18px;
    font-weight: 300;
    line-height: 29px;
  }
}

.light-20 {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}

.light-18 {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}

.black-22 {
  font-size: 22px;
  font-weight: 900;
  line-height: 26px;
  @include breakpoint(small down) {
    font-size: 19px;
    &.title-popup{
      margin-top: 15px;
    }
  }
}

.regular-24 {
  font-weight: 400;
  font-size: 24px;
}

.light-16 {
  font-weight: 300;
  font-size: 16px;
}

.light-14 {
  font-weight: 300;
  font-size: 14px;
}

// spacing
.lettersp-1 { letter-spacing: 1px; }


a { color: inherit; text-decoration: none; cursor: pointer; }
.block { display: block; };

.line-through {
  text-decoration: line-through;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}