#popup-subscribe-2 {
    display: none;
}

#side-popup {
    display: none;
    position: fixed;
    right: 15px;
    bottom: 15px;
    background-color: $light-brown;
    z-index: 99;
    width: 360px;
    text-align: center;
    padding: 35px;
    animation-name: side-popup-open;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    @include breakpoint(small down) {
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 30px;
    }

    input.input-blue {
        height: 46px;
        line-height: 47px;
        margin: 0 0 10px;
    }

    .button.button-form {
        padding: 13px 0;
        margin: 10px 0 0;
        line-height: 1.5;
    }

    p {
        margin-bottom: 20px;
    }
}

#button-newsletter {
    padding-top: 20px;
    padding-bottom: 20px;
}

#close-side-popup {
    font-size: 30px;
    position: absolute;
    line-height: normal;
    right: 12px;
    top: 2px;
    cursor: pointer;
    transition: opacity 0.15s;

    &:hover {
        opacity: 0.6;
    }
}

.social-button {
    display: block;
    color: white;
    padding: 12px 0;
    margin-top: 12px;
    transition: opacity 0.15s;

    &:hover {
        opacity: 0.7;
    }

    .fa {
        padding-right: 5px;
    }

    &.facebook {
        background-color: #4267b2;
    }

    &.twitter {
        background-color: #0099cc;
    }
}

@keyframes side-popup-open {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.popup-newsletter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-color: $white;
    z-index: 10;

    @include breakpoint(medium down) {
        overflow-y: auto;
        max-height: 100%;
    }
}

.text-popup-newsletter {
    font-size: 18px;
    padding: 0 63px;
    margin-bottom: 25px;
    @media screen and (min-width: 63.9375em) and (max-width: 75em) {
        margin-bottom: 30px;
        padding: 0 15px;
    }
    @include breakpoint(medium down) {
        padding: 0 20px;
        margin-bottom: 30px;
    }

    @include breakpoint(small down) {
        font-size: 14px;
        margin-bottom: 20px;
    }
}

.image-popup-newsletter {
    background-position: top center;
    height: 700px;
    background-size: cover;
    @include breakpoint(medium down) {
        height: 300px;
        margin-bottom: 20px;
    }
}

.title-popup-newsletter {
    margin-bottom: 25px;
    @media screen and (min-width: 63.9375em) and (max-width: 75em) {
        margin-bottom: 30px;
    }
    @include breakpoint(medium down) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    @include breakpoint(small down) {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 22px;
    }
}
.justify-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-button,
.close-button-come-fatto {
    position: absolute;
    line-height: normal;
    right: 20px;
    border-radius: 50px;
    top: 20px;
    cursor: pointer;
    transition: opacity 0.15s;
    background-color: $white;
    color: $black;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    img {
        width: 16px;
        height: 16px;
    }

    @include breakpoint(medium down) {
        overflow-y: auto;
        max-height: 100%;
    }
}

.flex-important {
    display: flex !important;
}

.reveal-overlay {
    justify-content: center;
    align-items: center !important;
    overflow-y: unset;
    background-color: rgba(10, 10, 10, 0.9) !important;
    &.flex-important {
        z-index: 50;
    }

    #popup-returns {
        @include breakpoint(medium down) {
            max-height: min(90vh, 560px);
            align-items: center;
            display: flex !important;
        }
    }
}

.reveal {
    top: 0 !important;

    &.large {
        padding: 0.5rem;
        width: 78%;

        @include breakpoint(medium down) {
            overflow-y: auto !important;
            width: 600px;
            height: min(90vh, 560px);
        }
    }

    @include breakpoint(small down) {
        top: unset;
        bottom: unset;
        min-height: unset !important;
        width: min(90vw, 360px) !important;
        margin: auto;
        height: min(90vh, 560px);
    }

    .return-button {
        cursor: pointer;
    }
}
