.alert-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  z-index: 100;
  font-weight: 400;
  font-family: $bebas;
  font-size: 20px;
  letter-spacing: 1px;
}

.alert {
  padding: 5px 0;
  animation-name: alert;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

    &.alert-danger {
      background-color: #d84e4e;
      color: white;
    }
    &.alert-success {
      background-color: #18ba6a;
      color: white;
    }
}

@keyframes alert {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

.question-box-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: $black, $alpha: .5);
    z-index: 100;

    .question-box {
        background-color: $white;
        padding: 40px;
        max-width: Min(500px, 100%);
        max-height: 100%;

        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 32px;

            .btn-cancel {
                margin: 0 8px 0 0;
            }

            .btn-confirm {
                margin: 0 0 0 8px;
            }

            .btn-cancel, .btn-confirm {
                cursor: pointer;
                height: 50px;
                padding: 9px 0;
                flex-basis: 50%;
                max-width: 50%;
            }
        }
    }
}