.category-nav {
    border-bottom: 1px solid;
    margin-bottom: 20px;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 77px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.55);

    @include breakpoint(small down) {
        top: 69px;
    }

    &.sticky {
        position: fixed;
        top: 56px;
        @include breakpoint(small down) {
            top: 48px;
        }
    }

    h1 {
        padding: 8px 0;
    }

    ul {
        padding: 13px 0 12px;
        margin: 0;
        list-style: none;
        text-align: right;
    }

    li {
        display: inline-block;
        margin: 0 20px;
        @include breakpoint(small down) {
            margin: 0 0 12px;
            float: left;
            width: 25%;
            text-align: center;
        }
        &:last-child {
            margin-right: 0;
            a {
                background-color: $darkblue;
                color: white;
                padding: 4px 20px;
                transition: 150ms;
                @include breakpoint(small down) {
                    padding: 4px;
                }
                &:hover {
                    background-color: $light-gray;
                    color: $darkblue;
                }
            }
        }
    }
}

#category-slider {
    max-width: 900px;
    margin: 0 auto 30px;

    &.slick-initialized .slick-slide {
        display: block;
    }

    .slick-dots {
        bottom: 20px;
    }

    .slick-dots li button:before {
        content: "";
        border-radius: 15px;
        border: 1px solid white;
        opacity: 1;
    }

    .slick-dots li.slick-active button:before {
        background-color: white;
        opacity: 1;
    }

    .slider-home-arrow {
        margin-top: 30%;
    }
}

.color-switcher {
    width: 350px;
    margin: 0 auto;
    @include breakpoint(small down) {
        width: 100%;
    }
}

.category-color-switcher {
    &.slick-initialized .slick-slide {
        display: block;
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 95px;
    }

    .slick-dots li button::before {
        content: "";
        border-radius: 45px;
        opacity: 1;
        width: 40px;
        height: 40px;
    }

    .slick-dots li.slick-active button:after,
    .slick-dots li:hover button:after {
        position: absolute;
        background-color: #000d1d;
        content: "";
        width: 20px;
        height: 2px;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .slick-dots {
        bottom: -62px;

        li {
            margin: 0 15px;
            width: 40px;
            height: 40px;
            @include breakpoint(small down) {
                margin: 0 10px;
            }

            button {
                width: 40px;
                height: 40px;
            }
        }
    }
}

.text-slider-container {
    padding-top: 70px;
    padding-bottom: 140px;
    @include breakpoint(medium down) {
        padding-top: 70px;
        padding-bottom: 120px;
    }
    @include breakpoint(small down) {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    hr {
        margin-bottom: 40px;
    }
}

.anchor {
    visibility: hidden;
    position: relative;
    top: -200px;
}

.category-text-slider {
    &.slick-initialized .slick-slide {
        display: block;
    }

    p {
        font-size: 22px;
        text-align: center;
        padding: 0 270px;
        @include breakpoint(medium down) {
            padding: 0 100px;
        }
        @include breakpoint(small down) {
            padding: 0 40px;
            font-size: 18px;
        }
    }

    .slick-dots li {
        button:before {
            content: "";
            height: 40px;
            width: 140px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            @include breakpoint(medium down) {
                height: 31px;
                width: 100px;
            }
            @include breakpoint(small down) {
                height: 20px;
                width: 55px;
            }
        }

        &:nth-child(1) button:before {
            background-image: url(/skins/current-skin/images/press/gq-magazine.svg);
        }
        &:nth-child(2) button:before {
            background-image: url(/skins/current-skin/images/press/wired.svg);
        }
        &:nth-child(3) button:before {
            background-image: url(/skins/current-skin/images/press/la-stampa.svg);
        }
        &:nth-child(4) button:before {
            background-image: url(/skins/current-skin/images/press/il-sole-24-ore.svg);
        }
    }

    .slick-dots li,
    .slick-dots li button {
        height: 40px;
        width: 140px;
        transition: 200ms;
        @include breakpoint(medium down) {
            height: 31px;
            width: 100px;
        }
        @include breakpoint(small down) {
            height: 20px;
            width: 55px;
        }
    }

    .slick-dots li.slick-active,
    .slick-dots li.slick-active button {
        transform: scale(1.06);
    }

    .slick-dots {
        bottom: -100px;
        @include breakpoint(medium down) {
            bottom: -70px;
        }
        @include breakpoint(small down) {
            bottom: -50px;
        }
    }

    .slider-home-arrow {
        margin-top: 3%;
        @include breakpoint(small down) {
            margin-top: 12%;
        }
    }

    .slick-dots li.slick-active button::before {
        opacity: 1;
    }

    .slick-dots li {
        margin: 0 45px;
        @include breakpoint(medium down) {
            margin: 0 25px;
        }
        @include breakpoint(small down) {
            margin: 0 10px;
        }
    }

    #arrow-right {
        @include breakpoint(small down) {
            right: 3px;
        }
    }

    #arrow-left {
        @include breakpoint(small down) {
            left: 3px;
        }
    }
}

.three-columns {
    @include breakpoint(small down) {
        text-align: center;
    }
}

.three-row {
    h2 {
        margin-top: 20px;
    }

    p {
        margin-bottom: 50px;
    }

    .three-row-reverse {
        @include breakpoint(small down) {
            order: 2;
        }
    }
}

.discover {
    padding: 32px 16px;

    img {
        width: 100%;
    }

    > a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            .text {
                color: rgba($color: $white, $alpha: 1);
            }
            .overlay {
                background-color: rgba($color: $black, $alpha: 0.5);
            }
        }
    }

    .text {
        display: table;
        position: absolute;
        color: rgba($color: $white, $alpha: 0);
        max-width: 30%;
        font-size: 40px;
        display: block;
        transition: all 0.4s;

        h4 {
            display: table-cell;
            text-align: center;
            vertical-align: middle;
        }
    }

    span {
        font-weight: 600;
        font-size: 20px;
    }

    .overlay {
        position: absolute;
        background-color: rgba($color: $black, $alpha: 0);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.4s;
    }

    @include breakpoint(small down) {
        padding: 16px 8px;
    }
}

.come-fatto-header {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: none !important;

    @include breakpoint(small down) {
        height: 50vh;
    }

    .text-container {
        display: table;
        max-width: 20vw;

        @include breakpoint(small down) {
            max-width: 50vw;
        }
    }

    h1 {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: $white;
        font-size: 12vh;

        @include breakpoint(small down) {
            font-size: 6vh;
        }
    }
}

.short-row {
    display: flex;
    justify-content: center;
    align-items: baseline;
    max-width: none !important;
    > * {
        text-align: center;
        max-width: 40%;

        @include breakpoint(small down) {
            max-width: 80%;
        }
    }
}

.come-fatto-slider-row {
    display: flex;
    justify-content: center;
    align-items: baseline;
    max-width: none !important;
    background-color: $lightblue;
    padding-bottom: 7vh;

    * {
        color: $white;
        text-align: center;
    }
}

.come-fatto-slider-row::before {
    content: "";
    display: block;
    border-radius: 50%;
    max-width: 100%;
    height: 400px;
    max-width: none;
    position: absolute;
    top: -14%;
    left: 0;
    right: 0;
    background-color: $lightblue;
    z-index: -1;

    @include breakpoint(small down) {
        height: 30px;
        top: -15px;
    }
}

#come-fatto-slider {
    position: relative;
    overflow-x: hidden;

    .swiper-slide > a {
        .title-container {
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .title {
                display: table;
                width: 10%;
                height: 10%;

                h1 {
                    text-transform: uppercase;
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    text-shadow: 2px 4px $black;
                    font-size: 40px;
                }
            }
        }

        .footer-text {
            position: absolute;
            bottom: 10%;
            left: 33.3%;
            width: 33.3%;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 1000;
        width: 1vw;

        @include breakpoint(medium down) {
            width: 3vw;
        }
    }

    .swiper-button-prev {
        left: 6%;
    }

    .swiper-button-next {
        right: 6%;
    }
}

$popup-card-bg-color: #f4f3f1;

#popup {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($black, 0.5);
    z-index: 10000;

    .card {
        display: table;
        position: relative;
        width: 143vh;
        height: 90vh;
        background-color: $white;

        @include breakpoint(small down) {
            height: 95vh;
            width: 90vw;
            padding: 0 0 7vh;
        }

        .content {
            display: table-cell;
            width: 100%;
            height: 100%;
            background-size: 60%;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: bottom;

            @include breakpoint(small down) {
                background-size: 105vw !important;
                background-position-x: left !important;
                background-position-y: 90% !important;
                max-width: 95vw;
            }

            h1,
            h2,
            h3,
            h4 {
                @include breakpoint(small down) {
                    margin: 0;
                }
            }

            h1 {
                margin: 0;
            }

            h3 {
                margin-top: 0;
            }

            h4 {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 5.606vh;

                @include breakpoint(small down) {
                    font-size: 14px !important;
                    margin-bottom: 10px !important;
                }
            }

            h4,
            p,
            span {
                font-size: 1.8vh;
                @include breakpoint(small down) {
                    font-size: 14px;
                }
            }

            h3 {
                font-size: 4vh;
                font-weight: 600;
            }

            h1 {
                font-size: 6.6vh;
                line-height: 6vh;
                font-weight: 600;
                width: max-content;

                @include breakpoint(small down) {
                    font-size: 5vh;
                    width: auto;
                }
            }

            .padding {
                display: flex;
                padding: 9vh 0 0 12vh;
                height: 100%;
                width: 100%;

                @include breakpoint(small down) {
                    padding: 7vh 10vw 0 7vw;
                }
            }

            .title {
                margin-top: 0;
            }

            .image1,
            .image2 {
                position: absolute;
                border: 1vh solid $white;

                @include breakpoint(small down) {
                    display: none;
                }
            }

            .image1 {
                top: 0;
                right: 20%;
                width: 80%;
            }

            .image2 {
                top: 33%;
                right: 10%;
                width: 80%;
            }
        }

        .button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 4vh;
            left: 25%;
            right: 25%;

            .button {
                cursor: pointer;
            }
        }

        .close-button-come-fatto{
            img {
              width: 24px;
              height: 24px;
           }
          }
    }
}

#popup .content br {
    @include breakpoint(medium down) {
        display: none;
    }
}

.close-come-fatto-popup.icon {
    position: absolute;
    top: 2.5vh;
    left: 2.5vw;
    width: 3.4vh;
    height: 2vw;
    cursor: pointer;

    @include breakpoint(small down) {
        left: auto;
        right: 10vw;
    }
}

.come-fatto-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $darkblue;
    width: 100%;
    max-width: none;
    padding: 80px 0;

    .button {
        border: 2px solid $white;
    }
}

html {
    overflow-x: hidden;
}
