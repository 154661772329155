.footer-links {
  margin: 0;
  padding: 0 30px;

    li {
      list-style: none;
      border-top: 1px solid;
      border-bottom: 1px solid;
      margin-top: -1px;
      padding: 6px 0 3px;
    }
}

.footer-social {
  display: inline-block;
  text-align: left;
  padding: 0 30px;

    p {
      margin-top: 6px;
      margin-bottom: 6px;
      @include breakpoint(small down) {
        opacity: .3;
      }
    }

    img {
      width: 30px;
      margin-right: 10px;
    }
}

.footer-info {
  margin-top: 6px;
  font-size: 14px;
  padding: 0 30px;
  letter-spacing: -0.005em;
}

footer .text-right {
  @include breakpoint(small down) {
    text-align: left;
    margin-bottom: 30px;
  }
}