@mixin center-flexbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin background-center {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.wrapper-no-img {
    position: relative;
}

.vh100 {
    height: 100vh;
}

.padding-65 {
    padding-top: 65px;
    padding-bottom: 65px;
}

.padding-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-150 {
    padding-top: 150px;
    padding-bottom: 150px;
    @include breakpoint(small down) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.padding-200 {
    padding-top: 200px;
    padding-bottom: 200px;
    @include breakpoint(small down) {
        padding-top: 150px;
        padding-bottom: 150px;
    }
}
.padding-right-10 {
    padding-right: 10px;
}
.margin-0 {
    margin: 0;
}

.margin-4 {
    margin: 4px 0;
}

.margin-8 {
    margin: 8px 0;
}

.margin-left-4 {
    margin-left: 4px;
}

.margin-right-4 {
    margin-right: 4px;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-right-8 {
    margin-right: 8px;
}

.margin-45 {
    margin: 45px;
    @include breakpoint(small down) {
        margin: 10px;
        padding: 0 15px;
    }
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-0 {
    margin-top: 0px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-right-10 {
    margin-right: 10px;
}

.line-100 {
    width: 100px;
    margin: 14px auto;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: inherit;

    &.extra-margin {
        margin-bottom: 24px;
    }
}

// mobile space
.mobile-space-50 {
    @include breakpoint(small down) {
        margin-bottom: 50px;
    }
}

// dimensions
.height-100 {
    height: 100%;
}
.width-100 {
    width: 100%;
}

// backgrounds
.background-light-gray {
    background-color: $light-gray;
}
.background-dark-blue {
    background-color: $darkblue;
}
.background-img {
    @include background-center;
}
.background-img-top {
    height: 100vh;
    @include background-center;
}
.background-red {
    background-color: $red;
}
.background-white {
    background-color: white;
}
.background-grey{
    background-color: $grey;
}

// z-index
.index-2 {
    z-index: 2;
    position: relative;
}

.background-img-overlay {
    position: relative;
    @include background-center;

    &::after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .overlay-content {
        z-index: 1;
        position: relative;
    }
}

.reveal:focus {
    outline: none;
    border: none;
}
