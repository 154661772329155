@import "node_modules/foundation-sites/scss/foundation";

$flex: true;

@if $flex {
    $global-flexbox: true !global;
}

@include foundation-global-styles;
@if not $flex {
    @include foundation-grid;
} @else {
    @include foundation-flex-grid;
}
//@include foundation-typography;
@include foundation-forms;
//@include foundation-button;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;

@if $flex {
    @include foundation-flex-classes;
}

@import "../plugin/slick/slick";
@import "../plugin/slick/slick-theme";
@import "node_modules/swiper/swiper";
@import "node_modules/swiper/swiper-vars";
@import "node_modules/slick-carousel/slick/slick";
@import "node_modules/slick-carousel/slick/slick-theme";
@import "node_modules/slick-lightbox/src/styles/slick-lightbox";
@import "settings";
@import "font";
@import "font-awesome/font-awesome";
@import "typo";
@import "buttons";
@import "spacing";
@import "inputs";
@import "components/help";
@import "components/footer";
@import "components/home";
@import "components/header";
@import "components/giftcard";
@import "components/catalog";
@import "components/product";
@import "components/category-page";
@import "components/cart";
@import "components/profile";
@import "components/alert";
@import "components/color-switcher";
@import "components/pickadate";
@import "components/popup";
@import "components/locations";

body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;

    > .main {
        flex-grow: 1;
    }
}

.slick-lightbox {
    .slick-lightbox-slick-item-inner {
        overflow: visible !important;
    }
}

.remember-me {
    text-align: left;
}