.catalog-loading {
    pointer-events: none;
    opacity: 0.7;
}

.product {
    padding: 0 10px;
    @include breakpoint(small down) {
        padding: 0;
    }
}

.product-img {
    a {
        display: block;
        transition: opacity 0.15s;

        &:hover {
            opacity: 0.7;
        }

        &.sold-out-link {
            display: flex;
            align-items: center;
            justify-content: center;

            picture,
            img {
                filter: brightness(0.8);
            }
        }
    }

    picture, img {
        width: 100%;
    }
}

.sold-out-catalog {
    font-family: $minion-pro;
    font-size: 40px;
    line-height: 48px;
    color: white;
    position: absolute;
    z-index: 1;

    @include breakpoint(small down) {
        font-size: 20px;
        line-height: 32px;
    }
}

.product-description {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px 15px;
    @include breakpoint(small down) {
        padding: 0;
        display: block;
        text-align: center;
        margin-top: 5px;
    }

    .product-code h4 {
        margin-bottom: 5px;
        @include breakpoint(small down) {
            font-size: 16px;
            margin-bottom: 0;
            line-height: 1;
        }
    }

    span {
        font-size: 16px;
    }

    .text-right {
        @include breakpoint(small down) {
            text-align: center;
        }
    }

    .product-price {
        @include breakpoint(small down) {
            margin-top: -6px;
        }
    }
}

.category-banner > div {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 360px;
    @include breakpoint(small down) {
        height: 200px;
    }

    h1 {
        @include breakpoint(small down) {
            font-size: 40px;
            letter-spacing: 1px;
        }
    }
}

// filters
body .switch-paddle:after,
body .switch-paddle {
    border-radius: 100px;
}

body .switch {
    display: inline-block;
    margin-bottom: 0;
}

body input:checked ~ .switch-paddle {
    background: #000d1d;
}

.filters {
    padding: 40px 0;
    @include breakpoint(small down) {
        padding: 20px 0;
    }
}

.switch-container {
    display: flex;
    align-items: center;
    padding-top: 10px;
    display: none;
    @include breakpoint(small down) {
        justify-content: center;
        margin-bottom: 15px;
    }

    > span {
        padding-left: 10px;
    }
}

.main-filters-dropdowns-container {
    background-color: $darkblue;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    .close-main-filter {
        cursor: pointer;
        position: absolute;
        top: 32px;
        right: 32px;
        color: $white;

        @include breakpoint(small down) {
            display: none !important;
        }
    }
}

.main-filters {
    display: inline-block;
    width: 100%;
    text-align: center;

    a {
        text-transform: capitalize;
        padding: 14px 0;
        background-color: $light-gray;
        width: 130px;
        display: inline-block;
        text-align: center;
        margin: 0 5px;
        transition: all 200ms;
        @include breakpoint(medium down) {
            width: 30.3%;
        }
        @include breakpoint(small down) {
            width: 29.3%;
        }
        &:hover,
        &.main-filters-open {
            background-color: #000d1d;
            color: white;
        }
    }
}

#mainsearch {
    border: none;
    box-shadow: none;
    padding: 0;
    height: auto;
    margin: 0;

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $darkblue;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $darkblue;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color: $darkblue;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: $darkblue;
    }
}

.main-filters-dropdown {
    display: none;
    text-align: center;
    margin: 30px 0;
    padding: 0;

    @include breakpoint(small down) {
        margin: 20px 0;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;
            margin-top: 8px;
            padding: 0 8px;
            width: calc(100% / 4);

            @include breakpoint(small down) {
                width: calc(100% / 2);
            }

            .checkbox-blue[type="checkbox"].catalog-filter + label {
                display: inline-flex;
                align-items: center;
                text-align: left;
                width: 100%;
                height: 100%;
                background-color: $white;
                border-radius: 4px;
                padding: 8px;
                padding-left: 32px;

                &::before,
                &::after {
                    border-radius: 50%;
                    top: auto;
                    left: 8px;
                }
            }

            .checkbox-blue[type="checkbox"].catalog-filter.color-filter + label {
                &::before {
                    border-color: var(--border-color);
                    background: var(--color);
                }
                &::after {
                    display: none;
                }
            }

            .checkbox-blue[type="checkbox"].catalog-filter.color-filter:checked + label {
                &::before {
                    border-color: black;
                    border-width: 2px;
                }
            }
        }
    }
}

.search-container {
    text-align: right;
    padding-top: 14px;
    @include breakpoint(small down) {
        display: none;
    }

    img {
        width: 19px;
        position: relative;
        top: -2px;
    }

    form {
        width: 110px;
        display: inline-block;
        padding-left: 7px;
    }
}

.add-to-wishlist {
    cursor: pointer;
    position: relative;

    &::before {
        content: "+";
        position: absolute;
        left: -15px;
        transition: all 0.2s;
        bottom: -3px;
    }

    &:hover::before {
        transform: rotate(180deg);
        color: $brown;
    }
}

.no-results {
    width: 100%;
    text-align: center;
    color: $brown;
    padding: 75px 15px;
    font-weight: normal;
    &.not-found {
        font-size: 200px;
        line-height: 232px;
        margin-bottom: 0;
        padding: 0;
    }
}
