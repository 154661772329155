.giftcard-selector {
  height: 280px;
  position: relative;
  transition: opacity 300ms ease;
  margin-bottom: 10px;

    &:hover {
      opacity: .65;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    p {
      max-width: 300px;
    }
}

.giftcard-image {
  padding-top: 154px;
  @include breakpoint(small down) {
    padding-top: 0;
  }
}