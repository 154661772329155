.location-title {
  font-weight: 400;
  font-size: 60px;
  letter-spacing: 2px;
  @include breakpoint(small down) {
    font-size: 40px;
  }
}

.location-subtitle {
  font-weight: 300;
  font-size: 40px;
  letter-spacing: 1px;
  @include breakpoint(small down) {
    font-size: 30px;
  }
}

.location-description {
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;
}

.locations-column {
  padding: 0 40px;
  @include breakpoint(medium down) {
    padding: 30px 0 0;
  }

    img {
      width: 110px;
      margin-bottom: 25px;
    }
}

.location-line {
  margin-bottom: 50px;
  @include breakpoint(medium down) {
    margin-bottom: 0;
  }
}

.location-event {
  @include breakpoint(medium down) {
    margin-top: 30px;
  }
}