#warmer-color-switcher {

  .slick-dots li:nth-child(1) button:before {
    background-color: #10294C;
  }
  .slick-dots li:nth-child(2) button:before {
    background-color: #425325;
  }
  .slick-dots li:nth-child(3) button:before {
    background-color: #9C6018;
  }
}

#chino-color-switcher {

  .slick-dots li:nth-child(1) button:before {
    background-color: #181820;
  }
  .slick-dots li:nth-child(2) button:before {
    background-color: #37342f;
  }
  .slick-dots li:nth-child(3) button:before {
    background-color: #605833;
  }
  .slick-dots li:nth-child(4) button:before {
    background-color: #c6b089;
  }
}

#button-color-switcher {

  .slick-dots li:nth-child(1) button:before {
    background-color: #211f2c;
  }
  .slick-dots li:nth-child(2) button:before {
    background-color: #c4c6d3;
  }
  .slick-dots li:nth-child(3) button:before {
    background-color: #dddbde;
  }
  .slick-dots li:nth-child(4) button:before {
    background-color: #aab8d5;
  }
}

#girocollo-color-switcher {

  .slick-dots li:nth-child(1) button:before {
    background-color: #58483b;
  }
  .slick-dots li:nth-child(2) button:before {
    background-color: #3e3a1d;
  }
  .slick-dots li:nth-child(3) button:before {
    background-color: #7e7a79;
  }
  .slick-dots li:nth-child(4) button:before {
    background-color: #1f2024;
  }
  .slick-dots li:nth-child(5) button:before {
    background-color: #131521;
  }
}

#tshirt-color-switcher {

  .slick-dots li:nth-child(1) button:before {
    background-color: #363543;
  }
  .slick-dots li:nth-child(2) button:before {
    background-color: #9a9b8d;
  }
  .slick-dots li:nth-child(3) button:before {
    background-color: #bebbb6;
  }
  .slick-dots li:nth-child(4) button:before {
    background-color: #d8d5d0;
  }
}

#dolcevita-color-switcher {

  .slick-dots li:nth-child(1) button:before {
    background-color: #1a1e2a;
  }
  .slick-dots li:nth-child(2) button:before {
    background-color: #736f6e;
  }
}