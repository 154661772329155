.button {
  font-size: 14px;
  font-weight: 900;
  font-family: $roboto;
  transition: opacity 300ms ease;
  display: inline-block;

  &.full-white {
    background: white;
    color: $darkblue;
    padding: 18px 60px;
    @include breakpoint(small down) {
      padding: 18px 30px;
    }
  }

  &.full-blue {
    background: $darkblue;
    color: white;
    padding: 18px 60px;
    border: none;
    @include breakpoint(small down) {
      padding: 18px 30px;
    }

      &.close-button {
        position: static;
        font-size: 14px;

          &:hover {
            color: white;
          }
      }
  }

  &.full-brown {
    background: $brown;
    color: white;
    padding: 18px 60px;
    @include breakpoint(small down) {
      padding: 18px 30px;
    }
  }

  &.button-form {
    padding: 25px 0;
    background-color: #000d1d;
    width: 100%;
    color: white;
    cursor: pointer;
    border: none;
  }

  &.empty-white {
    background: none;
    color: white;
    padding: 16px 60px;
    border: 2px solid white;
    position: relative;
    @include breakpoint(small down) {
      padding: 16px 30px;
    }
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: white;
      transform-origin: 0 100%;
      transition: transform 0.2s ease, -webkit-transform 0.2s ease;
      transform: scale(0, 1);
    }
    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scale(1, 1);
    }
  }

  &.empty-brown {
    background: none;
    color: $darkblue;
    padding: 16px 60px;
    border: 2px solid $brown;
    position: relative;
    @include breakpoint(small down) {
      padding: 16px 30px;
    }
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $brown;
      transform-origin: 0 100%;
      transition: transform 0.2s ease, -webkit-transform 0.2s ease;
      transform: scale(0, 1);
    }
    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scale(1, 1);
    }
  }

  &.empty-blue {
    background: none;
    color: $darkblue;
    padding: 9px 60px;
    border: 2px solid $darkblue;
    position: relative;
    margin-top: 10px;
    @include breakpoint(small down) {
      margin-bottom: 15px;
    }
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $darkblue;
      transform-origin: 0 100%;
      transition: transform 0.2s ease, -webkit-transform 0.2s ease;
      transform: scale(0, 1);
    }
    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scale(1, 1);
    }
  }

  &:hover {
    opacity: .85;
  }

  &:disabled {
    opacity: .4;
  }

  .fa {
    padding-left: 10px;
  }
}



.sold-out {
  background-color: $sold-out;
  text-transform: uppercase;
  padding: 25px 0;
  width: 100%;
  color: white;
  text-align: center;
}