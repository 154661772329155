header {
    width: 100%;
}

header:not(.header-top) {
    position: sticky;
    top: 0;
    z-index: 11;
}

.header-top {
    display: flex;
    background-color: $lightblue;
    overflow-x: hidden;

    .wrapper {
        display: flex;
        width: max-content;

        a, p {
            font-size: 14px;
            white-space: nowrap;
        }
    
        p {
            display: block;
            line-height: 28px;
        }
    
    }

    &:not(.scroll) {
        justify-content: center;

        .wrapper {
            a, p {
                &:not(:last-child)::after {
                    content: '|';
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }

    &.scroll {
        justify-content: flex-start;

        .wrapper {
            animation: scroll calc(10s + max(var(--elements-num) - 2) * 2.5s) linear infinite;

            @keyframes scroll {
                from {
                    transform: translateX(100vw);
                }
                to {
                    transform: translateX(-100%);
                }
            }

            a, p {
                &:not(:last-child)::after {
                    content: '|';
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }
}

.header-main {
    height: 56px;
    background-color: $darkblue;
    display: flex;
    justify-content: space-around;
    @include breakpoint(small down) {
        padding: 2px 12px 0;
        justify-content: space-between;
        height: 48px;
        align-items: center;
    }

    .header-main-logo {
        padding: 13px 0;
        @include breakpoint(small down) {
            width: auto;
            padding: 0;
        }

        img {
            width: 160px;
            @include breakpoint(small down) {
                width: 110px;
            }
        }
    }

    .header-main-nav {
        width: 380px;
        padding: 0;
        display: flex;
        align-items: center;
        
        @include breakpoint(medium down) {
            width: 215px;
        }
        @include breakpoint(small down) {
            width: auto;
            padding: 0;
        }

        a {
            margin: 0 28px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: relative;
            transition: 0.5s color ease;
            z-index: 2;
            @include breakpoint(medium down) {
                margin: 0 15px;
            }
            @include breakpoint(small down) {
                margin: 0 8px;
            }
            &.first-link {
                margin-left: 0;
            }
            &.last-link {
                margin-right: 0;
            }
            &::after {
                content: "";
                transition: 0.5s all ease;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                position: absolute;
                height: 2px;
                width: 0;
                background: white;
                left: 0;
                bottom: -3px;
                @include breakpoint(small down) {
                    display: none;
                }
            }
            &:hover::after {
                width: 100%;
            }
            &.active::after {
                width: 100%;
            }
        }

        .menu-icon {
            width: 21px;
            margin-top: -3px;
        }
    }
}

.menu-icon-left {
    width: 21px;
    margin-right: 5px;
}

.language-menu {
    background-color: $darkblue;
    display: none;

    > div {
        display: flex;
        align-items: center;
        max-width: 740px;
        margin: 0 auto;
        padding: 20px 0;
    }

    span {
        white-space: nowrap;
        margin-right: 15px;
    }

    select {
        height: 42px;
        border: none;
        margin: 0 15px 0 0;
    }

    .change-language {
        padding: 12px 40px;
    }
}

// profile
.header-main-logout {
    display: inline;
    position: relative;

    form {
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        text-align: center;
        background-color: $darkblue;
        padding-top: 28px;
        padding-bottom: 8px;
        display: none;
        animation-name: logout;
        animation-duration: 500ms;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        z-index: 1;
    }

    input {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
    }

    &:hover form {
        display: block;
    }
}

@keyframes logout {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

// menu
#offCanvasMenu {
    > div {
        padding: 129px 45px 45px;
        height: 100%;
        @include breakpoint(small down) {
            padding: 110px 45px 45px;
        }
    }

    .off-menu-image {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        img {
            width: 30px;
            cursor: pointer;
            transition: all 150ms ease;
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .off-menu-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        animation-name: mobile-menu-fade;
        animation-duration: 0.7s;
        animation-fill-mode: forwards;

        &.js-submenu {
            display: none;
        }

        &.js-desktop-menu {
            display: flex;
            @include breakpoint(small down) {
                display: none;
            }
        }

        &.js-submenu-open {
            display: flex;
        }
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;

        a {
            border-top: 1px solid $darkblue;
            border-bottom: 1px solid $darkblue;
            display: block;
            padding: 4px 0 2px;
            margin-top: -1px;
            opacity: 0.6;
            transition: opacity 150ms ease;
            @include breakpoint(small down) {
                padding: 0;
                opacity: 1;
            }
            &:hover {
                opacity: 1;
            }
        }
    }

    .js-mobile-menu-back {
        display: none;
    }

    .js-open-submenu {
        position: relative;

        &::after {
            content: url(/skins/current-skin/images/telablu-input-arrow.svg);
            width: 15px;
            height: auto;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }

    .js-mobile-menu {
        display: none;
        @include breakpoint(small down) {
            display: flex;
        }
    }
}

@keyframes mobile-menu-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// off canvas
.offcanvas-cart-container {
    padding: 100px 30px 0;
    height: calc(100% - 207px);
    overflow: auto;

    hr {
        margin: 5px 0;
    }
}

.offcanvas-cart-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h2 {
        font-weight: 400;
        font-size: 35px;
        line-height: normal;
    }

    span {
        cursor: pointer;
    }
}

.off-canvas.is-closed {
    visibility: visible;
}

.off-canvas-content {
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: -1;
}

.block-scroll {
    overflow-y: hidden;
}