// input blue
input.input-blue {
  width: 100%;
  border: 1px solid $darkblue;
  height: 64px;
  line-height: 65px;
  color: $darkblue;
  background: none;
  padding: 0 12px;
  font-weight: 700;
  font-size: 16px;

  &.error{
    border-color: $red;
    background-color: $light-red;
    margin-bottom:8px;
    &::placeholder {
      color: $red;
    }
  }
}

textarea.input-blue {
  width: 100%;
  border: 1px solid $darkblue;
  padding: 22px 12px 10px;
  height: 250px;
  background: none;
  color: $darkblue;
  font-weight: 700;
}

select.input-blue {
  margin: 0 0 1em;
  height: 64px;
  border-color: #000d1d;
  background-color: transparent;
}

input.input-blue::-webkit-input-placeholder, textarea.input-blue::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $darkblue;
  font-weight: 300;
}
input.input-blue::-moz-placeholder, textarea.input-blue::-moz-placeholder { /* Firefox 19+ */
  color: $darkblue;
  font-weight: 300;
}
input.input-blue:-ms-input-placeholder, textarea.input-blue:-ms-input-placeholder { /* IE 10+ */
  color: $darkblue;
  font-weight: 300;
}
input.input-blue:-moz-placeholder, textarea.input-blue:-moz-placeholder { /* Firefox 18- */
  color: $darkblue;
  font-weight: 300;
}

.input-menu {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
}

#reset-block {
  display: none;
}

.alert-message {
  padding: 5px 0;
}


.error {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 700;
  color: $red;
}

// newsletter
.container-input-newsletter {
  position: relative;
  margin: auto;
  &.footer {
    width: 350px;
  }

  @include breakpoint(small down){
    &.footer {
      width:min(70vw, 360px);
    }
  }

    input.input-newsletter {
       border: 2px solid;
       padding: 10px 10px;
       height: 44px;
       font-weight: 700;
     }

    .newsletter-arrow {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px 14px;
      cursor: pointer;
      transition: opacity 400ms ease;
      &:hover {
        opacity: .75;
      }
    }

    img {
      width: 15px;
    }
}
.newsletter-step2 {
  display: none;

  .button.button-form {
    width: 100%;

    &.popup {
      padding: 5px;
    }
  }
}

.success-message {
  font-weight: 400;
  color: #1ea75a;
  text-transform: uppercase;
  font-family: $bebas;
  font-size: 24px;
  margin: 0;
  display: none;
}

.alert {
  padding: 0 10px;
}

input.input-newsletter::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $darkblue;
  font-weight: 300;
}
input.input-newsletter::-moz-placeholder { /* Firefox 19+ */
  color: $darkblue;
  font-weight: 300;
}
input.input-newsletter:-ms-input-placeholder { /* IE 10+ */
  color: $darkblue;
  font-weight: 300;
}
input.input-newsletter:-moz-placeholder { /* Firefox 18- */
  color: $darkblue;
  font-weight: 300;
}



// checkbox
.checkbox-blue[type="checkbox"]:checked,
.checkbox-blue[type="checkbox"]:not(:checked)
{
  position: absolute;
  left: -9999px;
}


.checkbox-blue[type="checkbox"]:checked + label,
.checkbox-blue[type="checkbox"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: $darkblue;
  margin: 0;
  font-weight: 700;
}

.checkbox-blue[type="checkbox"]:not(:checked) + label.is-invalid-label {
  color: $red;
}


.checkbox-blue[type="checkbox"]:checked + label:before,
.checkbox-blue[type="checkbox"]:not(:checked) + label:before
{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid $darkblue;
  background: #fff;
}

.checkbox-blue.is-invalid-input[type="checkbox"]:not(:checked) + label:before {
  border-color: $red;
}

.checkbox-blue[type="checkbox"]:checked + label:after,
.checkbox-blue[type="checkbox"]:not(:checked) + label:after
{
  content: '';
  width: 18px;
  height: 18px;
  background: $darkblue;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.2s ease;
}

.checkbox-blue[type="checkbox"]:not(:checked) + label:after
{
  opacity: 0;
  transform: scale(0);
}

.checkbox-blue[type="checkbox"]:checked + label:after
{
  opacity: 1;
  transform: scale(1);
}

.checkbox-blue.catalog-filter[type="checkbox"]:checked + label:after {
  border-radius: 50%;
}


// select

.select-wrap {
  position: relative;
  display: inline-block;
  text-align: left;
  user-select: none;
  width: 100%;
  margin-bottom: 16px;
}

.select {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 64px;

    &::placeholder {
      color: #a7a7a7;
      font-size: 15px;
      font-weight: 400;
      line-height: 26px;
    }

     &.opened {
        .select-trigger:after {
          margin-top: 3px;
          transform: rotate(-135deg) translateY(-50%);
        }
        .options {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          transform: translateY(0);
        }
     }

     &.selected {
         .select-trigger {
           color: #000;
         }
     }
}

.select-trigger {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 64px;
  padding: 16px 12px;
  border: 1px solid $darkblue;
  cursor: pointer;
  line-height: 31px;
  font-weight: 700;

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      top: 50%;
      right: 25px;
      margin-top: -3px;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      transform: rotate(45deg) translateY(-50%);
      transition: all .4s ease-in-out;
      transform-origin: 50% 0;
    }
}

.options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0px;
  right: 0px;
  min-width: 100%;
  padding: 5px 0;
  margin-top: -1px;
  background: $light-gray;
  transition: all .4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  border: 1px solid $darkblue;
  border-top: 0;
}

.option {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #444;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
  text-align: center;

    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }
    &:last-of-type {
      border-bottom: 0;
      border-radius: 0 0 4px 4px;
    }
    &:hover {
      background-color: white;
    }
}





// radio button
.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      font-size: 16px;
      line-height: 24px;
      &:before {
        content: '';
        background: $light-gray;
        border-radius: 100%;
        border: 1px solid darken($light-gray, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: 0;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $brown;
          box-shadow: inset 0 0 0 4px $light-gray;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $brown;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $light-gray;
          border-color: darken($light-gray, 25%);
          background: darken($light-gray, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}