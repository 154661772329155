.profile-content {
  padding-left: 80px;
  @include breakpoint(medium down) {
    padding-left: 0;
  }

    h2 {
      margin: 35px 0 10px;
    }

    th {
      text-align: left;

        &:nth-child(1) {
          min-width: 10px;
        }
        &:nth-child(2) {
          min-width: 60px;
        }
        &:nth-child(3) {
          min-width: 160px;
        }
        &:nth-child(4) {
          min-width: 100px;
        }
        &:nth-child(5) {
          min-width: 100px;
        }
        &:nth-child(6) {
          min-width: 100px;
        }
        &:nth-child(7) {
          min-width: 80px;
        }
        &:nth-child(8) {
          min-width: 120px;
        }
    }

    dt {
      width: 50%;
      float: left;
      clear: left;
    }

    dd {
      width: 50%;
      float: left;
      margin: 0;
      text-align: right;
    }

    .pagination {
      list-style: none;
      padding: 0;
      margin: 10px 0 0;

        li {
          display: inline-block;
        }

        a {
          margin: 0 5px;
        }
    }

    dl {
      margin: 0;
    }

    h4 {
      margin-bottom: 5px;
    }
}


.order-row {
  cursor: pointer;

    &:hover {
      background-color: $light-brown;
    }

    td {
      border-bottom: 1px solid $darkblue;
      padding: 5px 0;
    }

    .order-row-indicator.rotate-arrow {
      transform: rotateZ(90deg);
    }
}

.order-controls:not(.selected) {
  display: none;
}


.export-files {
  margin-top: 15px;
}


.profile-menu {
  @include breakpoint(small down) {
    flex-direction: column;
  }

  li.active a {
    font-weight: 700;
    pointer-events: none;
    &::after {
      width: 100%;
    }
  }
}

.no-orders {
  margin-top: 160px;
  @include breakpoint(medium down) {
    margin-top: 40px;
  }
}


.order-controls-return {
  width: 100%;
  margin: 30px 0 20px;
  border-collapse: collapse;

  th {
    border-bottom: 1px solid;
  }
}

.order-controls-bg {
  background-color: $light-brown;
  padding: 20px 30px 10px;
}

.new-return-request {
  display: none;
}

.new-return-request {
  form {
    background-color: $light-gray;
    padding: 25px;
  }
}



.responsive-table {
  overflow-x:auto;

    > table {
      width: 100%;
      border-collapse: collapse;
    }
}


.copy-link-container {
  max-width: 500px;

    input[type=text] {
      border: 0;
      background-color: transparent;
      color: #000d1d;
      font-size: 18px;
      padding: .4em;
      width: 80%;
      margin: 0;
      height: 50px;
      box-shadow: none;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include breakpoint(small down) {
        width: 70%;
      }
    }

    input[type=submit] {
      border: 0;
      float: right;
      width: 20%;
      padding: 18px 0;
      @include breakpoint(small down) {
        width: 30%;
      }
    }

    .copy-link-icon {
      position: relative;
      left: 15px;
      top: 11px;
    }

    .copy-link-inner {
      border: 2px solid $darkblue;
      border-radius: 0;
      padding: 4px;
      width: 100%;
      height: 64px;
    }
}