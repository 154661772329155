.slider-home-slide {
    @include center-flexbox;
    display: flex !important;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    flex-direction: column;
    padding-top: 84px;
}

#slider-home {
    h2 {
        @include breakpoint(small down) {
            font-size: 40px;
            line-height: normal;
            padding: 0 50px;
        }
    }

    p {
        margin: 0;
    }
}

.slider-home-arrow {
    width: 20px;
    position: absolute;
    margin-top: 52vh;
    z-index: 1;
    top: 0;
    cursor: pointer;
}

#arrow-right {
    right: 35px;
    @include breakpoint(small down) {
        right: 15px;
    }
}

#arrow-right-mobile {
    right: 35px;
    @include breakpoint(small down) {
        right: 15px;
        padding: 8px 16px;
        width: 52px;
    }
}

#arrow-left {
    left: 35px;
    @include breakpoint(small down) {
        left: 15px;
    }
}

#arrow-left-mobile {
    left: 35px;
    @include breakpoint(small down) {
        left: 15px;
        padding: 8px 16px;
        width: 52px;
    }
}

// animation line-ball
.home-animation {
    @include center-flexbox;
    @include breakpoint(small down) {
        padding: 0 20px;
        width: 320px;
        margin: 25px auto 20px;
    }

    img {
        height: 70px;
        width: auto;
        @include breakpoint(small down) {
            height: 50px;
        }
    }

    .home-animation-container {
        width: 300px;
        height: 70px;
        position: relative;
        margin: 0 35px;

        .home-animation-line {
            height: 3px;
            background-color: $darkblue;
            width: 100%;
            margin-top: 33px;
        }

        .home-animation-ball {
            margin-top: -11px;
            width: 20px;
            height: 20px;
            background-color: $darkblue;
            position: absolute;
            border-radius: 50%;
            animation-name: line-ball;
            animation-duration: 7s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-fill-mode: forwards;
            @include breakpoint(small down) {
                animation-duration: 5s;
            }
        }
    }
}

@keyframes line-ball {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(280px);
    }

    100% {
        transform: translateX(0px);
    }
}

@include breakpoint(small down) {
    @keyframes line-ball {
        0% {
            transform: translateX(0px);
        }

        50% {
            transform: translateX(75px);
        }

        100% {
            transform: translateX(0px);
        }
    }
}

.home-product {
    a {
        &:hover img {
            transform: scale(1.03);
        }
    }

    img {
        width: 100%;
        transition: all 150ms ease;
    }
}

.about-us-box {
    a:hover img {
        opacity: 0.9;
    }

    img {
        transition: opacity 300ms ease;
    }
}

.position-left, .position-right {
    width: Min(100vw, 350px);
    top: 0;
    height: 100%;
    overflow-y: auto;
}

.position-right {
    right: 0;
    transform: translateX(100%);
}

.position-left {
    left: 0;
    transform: translateX(-100%);
}